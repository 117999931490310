import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Container, Alert, Button } from 'reactstrap';
import Widget from '../../../components/Widget';
import { loginUser, receiveToken, doInit } from '../../../actions/auth';
import { useTranslation } from "react-i18next";
import Logo from '../../../images/logo.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const isFetching = useSelector((state) => state.auth.isFetching);
    const errorMessage = useSelector((state) => state.auth.errorMessage);

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email, password }));
    };

    const handleSignUp = () => {
        history.push('/register'); // Use history.push for navigation
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            dispatch(receiveToken(token));
            dispatch(doInit());
        }
    }, [dispatch, location.search]);
    const { t } = useTranslation();

    return (
        <div className="auth-page">
            <Container>
                <div className="auth-logo d-flex justify-content-center">
                    <img src={Logo} alt={t("Logo")} />
                </div>
                <Widget
                    className="widget-auth mx-auto"
                    title={<h3 className="mt-0">{t("Login to your Web App")}</h3>}
                >
                    <p className="widget-auth-info">
                        {t("Use your username to sign in.")}
                    </p>
                    <form className="mt" onSubmit={handleLogin}>
                        {errorMessage && (
                            <Alert className="alert-sm" color="danger">
                                {t(errorMessage?.message)}
                            </Alert>
                        )}
                        <div className="form-group">
                            <input
                                className="form-control no-border"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                type="text"
                                required
                                name="email"
                                placeholder={t("Username")}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                className="form-control no-border"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                type="password"
                                required
                                name="password"
                                placeholder={t("Password")}
                            />
                        </div>
                        <div className="form-group">
                            <Link to="/forgot">{t("Reset password")}</Link>
                        </div>
                        <Button
                            type="submit"
                            color="primary"
                            className="auth-btn mb-3"
                            size="sm"
                        >
                            {isFetching && <i className="fa fa-spin fa-spinner me-2" />}
                            {t("Login")}
                        </Button>
                    </form>
                    <p className="widget-auth-info">
                        {t("Don't have an account? Sign up now!")}
                    </p>
                    <div className="text-center d-block">
                        <button
                            className="btn btn-link text-center"
                            onClick={handleSignUp}
                        >
                            {t("Create an Account")}
                        </button>
                    </div>

                </Widget>
            </Container>
        </div>
    );
};

export default Login;
