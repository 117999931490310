import React from 'react';
import { Container, Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ThankYouPage = () => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <Card className="text-center shadow p-4" style={{ maxWidth: '500px' }}>
                <CardBody>
                    <CardTitle tag="h3" className="mb-3">{t("Thank You for Registering!")}</CardTitle>
                    <CardText className="mb-4">
                        {t("Your account is under review. Please wait for admin approval before you can log in.")}
                    </CardText>
                    <Button color="primary" onClick={() => history.push('/login')}>{t("Login")}</Button>
                </CardBody>
            </Card>
        </Container>
    );
};

export default ThankYouPage;
