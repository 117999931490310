import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { Button, Input, Label, Row, Col, FormGroup, Form } from "reactstrap";
import { toast } from "react-toastify";
import Widget from "../../components/Widget";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import e from "../forms/elements/Elements.module.scss";
import Dropzone from "react-dropzone";
import AsyncSelectField from "../../components/async-select";
import config from "../../config";
import { useSelector } from "react-redux";
import ConnectedUser from "./components/connected-user";
import { useHistory } from "react-router-dom";
import IconUploader from "./components/upload-icon";


export default function CompanyEdit() {
    const history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState();
    const params = useParams();
    const { t } = useTranslation();
    const companyId = currentUser.role === "ROLE_ADMIN" ? params.companyId : currentUser.userCompanyId;
    const [previewLogo, setPreviewLogo] = useState(null);
    const [previewBackground, setPreviewBackground] = useState(null);

    const fetch = async () => {
        const resp = await axios.get("/companies/" + companyId)
        setCompany(resp.data);
    };

    const initialValues = {
        name: "",
        organizationNr: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        country: "",
        description: "",
        comission: 0,
        socialMedia: [
            { name: "Facebook", link: "", icon: "" },
            { name: "Snapchat", link: "", icon: "" },
            { name: "Instagram", link: "", icon: "" },
            { name: "LinkedIn", link: "", icon: "" },
            { name: "Youtube", link: "", icon: "" },
            { name: "Twitter", link: "", icon: "" },
        ],
        categoryIds: [],
        logo: null,
        background: null,
        signatureEmail: "",
        signatureName: "",
        invoiceEmail: "",
        anotherSocialMedia: [
        ]
    };


    const handleLogoDrop = (acceptedFiles, setFieldValue, type) => {
        const file = acceptedFiles[0];
        if (file) {

            setFieldValue(type, file);
            if (type === "background") {
                setPreviewBackground(URL.createObjectURL(file));
            } else if (type === "logo") {
                setPreviewLogo(URL.createObjectURL(file));
            }

        }
    };

    const addSocialMedia = () => {
        formik.setFieldValue("anotherSocialMedia", [
            ...formik.values.anotherSocialMedia,
            { name: "", link: "", icon: "" },
        ]);
    };

    function mergeSocialMedia(defaultValues, response) {
        const responseMap = new Map(response.map(item => [item.name, item]));

        // Extract fixed social media values
        const socialMedia = defaultValues.map(defaultItem => {
            const responseItem = responseMap.get(defaultItem.name);
            return responseItem ? responseItem : defaultItem;
        });

        // Extract additional social media fields that are not in default values
        const anotherSocialMedia = response.filter(item =>
            !defaultValues.some(defaultItem => defaultItem.name === item.name)
        );

        return { socialMedia, anotherSocialMedia };
    }


    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            name: Yup.string().required(t("Company name is required")),
            email: Yup.string().email(t("Invalid email format")).required(t("Email is required")),
            phone: Yup.string().required(t("Phone is required")),
            address: Yup.string().required(t("Address is required")),
            city: Yup.string().required(t("City is required")),
            country: Yup.string().required(t("Country is required")),
        }),
        onSubmit: (values) => {
            const formData = new FormData();
            const combinedSocialMedia = [...values.socialMedia] || [];
            (values.anotherSocialMedia || []).forEach((social) => {
                combinedSocialMedia.push(social);
            });

            combinedSocialMedia.forEach((social, index) => {
                formData.append(`socialMedia[${index}][name]`, social.name);
                formData.append(`socialMedia[${index}][link]`, social.link);
                formData.append(`socialMedia[${index}][icon]`, social.icon);
                formData.append(`socialIcons[${index}]`, social.icon);
            });

            if (values.logo) {
                formData.append("logo", values.logo);
            } else {
                formData.append("logo", "");
            }
            if (values.background) {
                formData.append("background", values.background);
            } else {
                formData.append("background", "");
            }

            Object.keys(values).forEach((key) => {
                if (
                    key !== "logo" &&
                    key !== "background" &&
                    key !== "socialMedia" &&
                    key !== "anotherSocialMedia"
                ) {
                    if (Array.isArray(values[key])) {
                        values[key].forEach((item, index) =>
                            formData.append(`${key}[${index}]`, item)
                        );
                    } else if (values[key] !== null && values[key] !== undefined) {
                        formData.append(key, values[key]);
                    }
                }
            });

            setLoading(true);
            if (companyId) {
                axios
                    .post(`/companies/${companyId}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(() => {
                        toast.success(t("The company has been updated."));
                        fetch();
                    })
                    .catch((error) => {
                        toast.error(t("The company hasn't been updated."));
                    })
                    .finally(() => {
                        setLoading(false);
                    });

            } else {
                axios
                    .post(`/companies/`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then(() => {
                        toast.success(t("The company has been created."));
                        history.push("/admin/companies");
                    })
                    .catch((error) => {
                        toast.error(t("Couldn't create company"));
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }

        },
        enableReinitialize: true,
    });

    const handleChangeConnectedUser = useCallback((user) => {
        if (user) {
            formik.setFieldValue("connectedUserId", user.id);
        } else {
            formik.setFieldValue("connectedUserId", -1);
        }
    }, [companyId, company]);

    useEffect(() => {
        if (!companyId) {
            return
        }

        fetch();

    }, [companyId]);

    const handleChangeIcon = useCallback((icon, index, name) => {
        if (name) {
            formik.setFieldValue(`socialMedia[${index}].icon`, icon);
        } else {
            formik.setFieldValue(`anotherSocialMedia[${index}].icon`, icon);
        }
    }
        , [formik.values.socialMedia]);


    useEffect(() => {
        if (company) {
            const { socialMedia, anotherSocialMedia } = mergeSocialMedia(initialValues.socialMedia, company.socialMedia || []);

            formik.setValues({
                ...company,
                socialMedia: socialMedia,
                anotherSocialMedia: anotherSocialMedia,
                categoryIds: company.categories.map((category) => category.id),
            });

            if (company?.logo) {
                setPreviewLogo(config.baseURL + "/" + company.logo);
            }

            if (company?.background) {
                setPreviewBackground(config.baseURL + "/" + company.background);
            }

        }
    }, [company]);

    const handleRemoveSocialMedia = (index) => {
        const updatedList = [...formik.values.anotherSocialMedia];
        updatedList.splice(index, 1);
        formik.setFieldValue("anotherSocialMedia", updatedList);
    };

    return (
        <>
            <div className="page-top-line">
                <h2 className="page-title">
                    {t(companyId ? "Company edit" : "Company create")}
                </h2>
            </div>
            <Widget>

                <Form onSubmit={formik.handleSubmit}>
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup>
                                <Label htmlFor="name">{t("Company Name")}</Label>
                                <Input
                                    id="name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.name && !!formik.errors.name}
                                />
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-danger">{formik.errors.name}</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="email">{t("Email")}</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.email && !!formik.errors.email}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="phone">{t("Phone")}</Label>
                                <Input
                                    id="phone"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.phone && !!formik.errors.phone}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className="text-danger">{formik.errors.phone}</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="address">{t("Address")}</Label>
                                <Input
                                    id="address"
                                    name="address"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.address && !!formik.errors.address}
                                />
                                {formik.touched.address && formik.errors.address && (
                                    <div className="text-danger">{formik.errors.address}</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="city">{t("City")}</Label>
                                <Input
                                    id="city"
                                    name="city"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.city && !!formik.errors.city}
                                />
                                {formik.touched.city && formik.errors.city && (
                                    <div className="text-danger">{formik.errors.city}</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="country">{t("Country")}</Label>
                                <Input
                                    id="country"
                                    name="country"
                                    value={formik.values.country}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.country && !!formik.errors.country}
                                />
                                {formik.touched.country && formik.errors.country && (
                                    <div className="text-danger">{formik.errors.country}</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="bankAccountNumber">{t("Bank Account Number")}</Label>
                                <Input
                                    id="bankAccountNumber"
                                    name="bankAccountNumber"
                                    value={formik.values.bankAccountNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.bankAccountNumber && !!formik.errors.bankAccountNumber}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="signatureEmail">{t("Signature email")}</Label>
                                <Input
                                    id="signatureEmail"
                                    name="signatureEmail"
                                    type="email"
                                    value={formik.values.signatureEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.signatureEmail && !!formik.errors.signatureEmail}
                                />
                                {formik.touched.signatureEmail && formik.errors.signatureEmail && (
                                    <div className="text-danger">{formik.errors.signatureEmail}</div>
                                )}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="signatureName">{t("Signature name")}</Label>
                                <Input
                                    id="signatureName"
                                    name="signatureName"
                                    value={formik.values.signatureName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.signatureName && !!formik.errors.signatureName}
                                />
                                {formik.touched.signatureName && formik.errors.signatureName && (
                                    <div className="text-danger">{formik.errors.signatureName}</div>
                                )}
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="organizationNr">{t("Organization Number")}</Label>
                                <Input
                                    id="organizationNr"
                                    name="organizationNr"
                                    value={formik.values.organizationNr}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="invoiceEmail">{t("Invoice email")}</Label>
                                <Input
                                    id="invoiceEmail"
                                    name="invoiceEmail"
                                    value={formik.values.invoiceEmail}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}><FormGroup>
                            <Label for="description">{t("description", {
                                context: "company"
                            })}</Label>
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={formik.touched.description && !!formik.errors.description}
                            />
                        </FormGroup></Col>
                        {currentUser.role === "ROLE_ADMIN" && <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="comission">{t("Comission")}</Label>
                                <Input
                                    id="comission"
                                    type="number"
                                    name="comission"
                                    value={formik.values.comission}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    invalid={formik.touched.comission && !!formik.errors.comission}
                                />
                                {formik.touched.comission && formik.errors.comission && (
                                    <div className="text-danger">{formik.errors.comission}</div>
                                )}
                            </FormGroup>
                        </Col>}


                    </Row>
                    <Row>


                        {(currentUser.role === "ROLE_ADMIN" && companyId) && (<Col md="6">
                            <ConnectedUser companyId={companyId} onChange={handleChangeConnectedUser} />
                        </Col>)}

                        <Col md={6}>
                            <FormGroup>
                                <Label for="categoryIds">{t("Categories")}</Label>
                                <AsyncSelectField
                                    isMulti
                                    defaultValue={formik.values.categoryIds}
                                    name="categoryIds"
                                    label="Category" url="/company-categories"

                                    handleSelectChange={(value) => {
                                        formik.setFieldValue("categoryIds", value.map((item) => item.id));
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md="6">
                            <Row className="mb-3">
                                <Col md={12}>
                                    <Label>{t("Logo")}</Label>
                                </Col>
                                {!!previewLogo ? (
                                    <Col md={6}>
                                        <Row className="image-preview">
                                            <Col xs={12} className="image-container text-center">
                                                <div className="image-wrapper">
                                                    <img src={previewLogo} alt="Channel Logo" className="img-fluid" />
                                                </div>
                                                <div className="mt-2 d-flex justify-content-between">
                                                    <Button
                                                        size="xs"
                                                        color="danger"
                                                        onClick={() => {
                                                            setPreviewLogo(null);
                                                            formik.setFieldValue("logo", null);
                                                        }}
                                                    >
                                                        {t("Remove")}
                                                    </Button>
                                                    <Button
                                                        size="xs"
                                                        color="primary"
                                                        onClick={() => {
                                                            document.querySelector(".hidden-upload-input").click();
                                                        }}
                                                    >
                                                        {t("Upload")}
                                                    </Button>
                                                </div>
                                                {/* Hidden input for manual upload */}
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden-upload-input"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                        handleLogoDrop(e.target.files, formik.setFieldValue, "logo")
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : (
                                    <Col md={6} className="d-flex align-items-center">
                                        <Dropzone
                                            className={`dropzone ${e.dropzone}`}
                                            onDrop={(acceptedFiles) => handleLogoDrop(acceptedFiles, formik.setFieldValue, "logo")}
                                            accept="image/*"
                                            multiple={false}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps({ className: `dropzone align-items-center d-flex ${e.dropzone}` })}>
                                                        <input {...getInputProps()} />
                                                        <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Col>
                                )}
                            </Row>
                        </Col>

                        <Col md="6">
                            <Row className="mb-3">
                                <Col md={12}>
                                    <Label>{t("Background")}</Label>
                                </Col>
                                {!!previewBackground ? (
                                    <Col md={6}>
                                        <Row className="image-preview">
                                            <Col xs={12} className="image-container text-center">
                                                <div className="image-wrapper">
                                                    <img src={previewBackground} alt="Background" className="img-fluid" />
                                                </div>
                                                <div className="mt-2 d-flex justify-content-between">
                                                    <Button
                                                        size="xs"
                                                        color="danger"
                                                        onClick={() => {
                                                            setPreviewBackground(null);
                                                            formik.setFieldValue("background", null);
                                                        }}
                                                    >
                                                        {t("Remove")}
                                                    </Button>
                                                    <Button
                                                        size="xs"
                                                        color="primary"
                                                        onClick={() => {
                                                            document.querySelector(".hidden-upload-input").click();
                                                        }}
                                                    >
                                                        {t("Upload")}
                                                    </Button>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="hidden-upload-input"
                                                    style={{ display: "none" }}
                                                    onChange={(e) =>
                                                        handleLogoDrop(e.target.files, formik.setFieldValue, "background")
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : (
                                    <Col md={6} className="d-flex align-items-center">
                                        <Dropzone
                                            className={`dropzone ${e.dropzone}`}
                                            onDrop={(acceptedFiles) => handleLogoDrop(acceptedFiles, formik.setFieldValue, "background")}
                                            accept="image/*"
                                            multiple={false}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps({ className: `dropzone align-items-center d-flex ${e.dropzone}` })}>
                                                        <input {...getInputProps()} />
                                                        <p>{t("Drag 'n' drop some files here, or click to select files")}</p>
                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>


                    <h4 className="mt-4">{t("Social media")}</h4>
                    <hr />
                    <Row >
                        {formik.values.socialMedia.map((social, index) => (
                            <Col key={index} md="6">

                                <FormGroup>
                                    <Label>{social.name}</Label>
                                    <div className="d-flex">
                                        <Input
                                            placeholder={t("Social media name")}
                                            value={social.link}
                                            onChange={(e) =>
                                                formik.setFieldValue(`socialMedia[${index}].link`, e.target.value)
                                            }
                                        />
                                        <div className="ms-2">
                                            <IconUploader iconUrl={social.icon} name={social.name} index={index} onUpload={handleChangeIcon} />
                                        </div>
                                    </div>

                                </FormGroup>


                            </Col>
                        ))}
                    </Row>
                    <hr />
                    <h6 className="fw-semi-bold">{t("Another social media")}</h6>
                    {formik.values.anotherSocialMedia.map((social, index) => (
                        <Row key={index} className="mb-2">
                            <Col md="5">
                                <FormGroup>
                                    <div className="d-flex">
                                        <Input
                                            placeholder="Social Media Name"
                                            value={social.name}
                                            onChange={(e) =>
                                                formik.setFieldValue(`anotherSocialMedia[${index}].name`, e.target.value)
                                            }
                                        />
                                        <div className="ms-2">
                                            <IconUploader iconUrl={social.icon} index={index} onUpload={handleChangeIcon} />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <Input
                                        placeholder="Social Media Link"
                                        value={social.link}
                                        onChange={(e) =>
                                            formik.setFieldValue(`anotherSocialMedia[${index}].link`, e.target.value)
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="1">
                                <FormGroup>
                                    <div> <Button size="md" color="danger" onClick={() => handleRemoveSocialMedia(index)}>
                                        <i className="fa fa-trash" />
                                    </Button></div>

                                </FormGroup>
                            </Col>
                        </Row>
                    ))}
                    <div className="d-flex align-items-end justify-content-end">
                        <Button type="button" color="primary" onClick={addSocialMedia}>
                            {t("Add more")}
                        </Button>
                    </div>

                    <Button type="submit" color="primary" disabled={loading}>
                        {loading && <i className="fa fa-spin fa-spinner me-2" />}

                        {companyId ? t("Save") : t("Create")}
                    </Button>
                </Form>

            </Widget>
        </>
    );
}



