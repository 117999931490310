import React, { useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

const BreadcrumbHistory = ({ url }) => {
  const { t } = useTranslation();
  const breadcrumbs = useMemo(() => {
    const route = url.split('/')
      .slice(1)
      .map(segment => segment
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
      );
    
    return route.map((item, index) => {
      const middlewareUrl = "/" + url.split('/').slice(1, index + 2).join('/');
      return (
        <BreadcrumbItem key={uuidv4()}>
          {index === route.length - 1 ? (
            t(item).toLocaleLowerCase()
          ) : (
            <Link to={middlewareUrl}>{t(item).toLocaleLowerCase()}</Link>
          )}
        </BreadcrumbItem>
      );
    });
  }, [url]);

  return (
    <div>
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem><Link to="/">{t("Home")}</Link></BreadcrumbItem>
        {breadcrumbs}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbHistory;