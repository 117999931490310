const hostApi = process.env.NODE_ENV === "development" ? "http://localhost" : "https://sing-generator-node.herokuapp.com";
const portApi = process.env.NODE_ENV === "development" ? 8080 : "";
const baseURLApi = process.env.NODE_ENV === "development" ? "https://api.brandnation.se/api/v1" : "https://api.brandnation.se/api/v1";
const redirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/sing-app-react" : "https://demo.flatlogic.com/sing-app-react";
const baseURL = "https://api.brandnation.se";
export default {
  redirectUrl,
  hostApi,
  portApi,
  baseURLApi,
  baseURL,
  remote: "https://sing-generator-node.herokuapp.com",
  tinymceApiKey: "w7xmanetwg40ytcncg1qvwe3e9uym7ecinqo4cw3r3ubo45q",
  isBackend: process.env.REACT_APP_BACKEND,
  defaultStatusNewId: 6,
  formQuoteId: 1,
  auth: {
    email: 'admin@flatlogic.com',
    password: 'password'
  },
  quoteStatuses: {
    "esignComplete": 20,
    "esignCancel": 19,
    "esignPending": 18,
    "complete": 14,
    "orderCreated": 13,
    "esignatureSigned": 12,
    "cancel": 11,
    "changes": 10,
    "denied": 9,
    "approve": 8,
    "sendRequest": 7,
    "new": 6
  },
  app: {
    sidebarColors: {
      first: '#3D3D3D',
      second: '#4B505F',
      third: '#483CB6',
      fourth: '#EFF2F5',
      fifth: '#20AE8C'
    },
    navbarColors: {
      first: '#ffffff',
      second: '#E2E7EC',
      third: '#C9D1FB',
      fourth: '#C1C3CF',
      fifth: '#0C2236',
      sixth: '#6FB0F9'
    },
    colors: {
      dark: "#002B49",
      light: "#FFFFFF",
      sea: "#004472",
      sky: "#E9EBEF",
      wave: "#D1E7F6",
      rain: "#CCDDE9",
      middle: "#D7DFE6",
      black: "#13191D",
      salad: "#21AE8C",
      seaWave: "#483CB6",
      grad: "#4B505F",
      blueSky: "#EFF2F5"
    },
    themeColors: {
      primary: "#003C32",
      secondary: "#B5E517",
      success: "#26CD5F",
      info: "#10CFD0",
      warning: "#EBB834",
      danger: "#FF5574",
      inverse: "#30324C",
      default: "#9083F7"
    },
  }
};
