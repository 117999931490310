import React, { useEffect, useState, memo, forwardRef, useImperativeHandle } from 'react';
import { useFormik, FormikProvider } from 'formik';
import InputFormItem from '../../components/FormItems/items/InputFormItem';
import RadioFormItem from '../../components/FormItems/items/RadioFormItem';
import TextAreaFormItem from '../../components/FormItems/items/TextAreaFormItem';
import CheckboxFormItem from '../../components/FormItems/items/CheckboxFormItem';
import FormValidations from '../../components/FormItems/formValidations';
import axios from 'axios';
import { Button, Row, Col, ButtonToolbar } from 'reactstrap';
import { useSelector } from 'react-redux';
import Widget from '../../components/Widget/Widget';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import config from "../../config";
import useFetch from '../../components/hooks/use-fetch';
import Loader from '../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';


const FormSubmit = memo(forwardRef(({ quoteId, submission, isDisabled }, ref) => {
    const history = useHistory();
    const [schema, setSchema] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const { currentUser } = useSelector(state => state.auth);
    const [currentSubQ, setCurrentSubQ] = useState(null);

    const { data: form, loading: formLoading } = useFetch(`/forms/${config.formQuoteId}`);

    useEffect(() => {
        if (submission) {
            formik.setValues(convertFormData(submission));
        }
    }, [submission]);

    const doSubmit = async (values) => {
        if (!values.questions || values.questions.length === 0) {
            toast.error(t('Please fill the form before submitting.'));
            return;
        }
        setSubmitting(true);
        try {
            await axios.post('/submit-forms/', values);
            if (submission) {
                toast.success(t('Form updated'));
            } else {
                toast.success(t('Form submitted'));
                if (currentUser.role === 'ROLE_COMPANY') {
                    history.push(`/app/quotes/${quoteId}/edit`);
                } else {
                    history.push(`/admin/quotes/${quoteId}/edit`);
                }
            }
        } catch (error) {
            toast.error(t('Error submitting form'));
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };

    const formValidations = (schema) => {
        return FormValidations(schema);
    };


    const formik = useFormik({
        initialValues: {},
        validationSchema: formValidations(schema),
        onSubmit: values => {
            const data = transformFormikValues(values, currentUser.id, form.id, quoteId);
            doSubmit(data);

        },
    });

    useImperativeHandle(ref, () => ({
        submit: formik.handleSubmit,
    }));

    const buildSchema = (questions, isSub) => {
        const newSchema = {};
        questions.forEach(question => {
            const fieldKey = question.id;
            newSchema[fieldKey] = {
                type: question.type === "checkbox" ? "numberArray" : "string",
                label: question.name,
                required: (question.type === 'radio' || question.type === 'checkbox') && !isSub ? true : question.required,
                options: question.answers?.map(answer => ({
                    value: answer.id,
                    label: answer.name,
                    hasSubQuestions: !!answer.subQuestions?.length,
                    helpText: answer.helpText,
                })) ?? [],
            };
            question.answers?.forEach(answer => {
                if (answer.subQuestions?.length) {
                    Object.assign(newSchema, buildSchema(answer.subQuestions, true));
                }
            });
        });
        return newSchema;
    };

    const transformFormikValues = (values, userId, formId, quoteId) => {
        const questions = [];

        Object.entries(values).forEach(([questionId, answer]) => {
            if (Array.isArray(answer)) {
                answer.forEach(ans => {
                    questions.push({ id: parseInt(questionId, 10), answer: ans });
                });
            } else {
                questions.push({ id: parseInt(questionId, 10), answer });
            }
        });

        return {
            userId,
            formId,
            questions,
            quoteId,
        };
    };


    const convertFormData = (data) => {
        const result = {};

        const processQuestions = (questions) => {
            questions.forEach((question) => {
                if (question.type === "checkbox") {
                    result[question.id] = question.answers
                        ? question.answers
                            .filter((answer) => answer.check === "Yes")
                            .map((answer) => answer.id)
                        : [];

                    question.answers?.forEach((answer) => {
                        if (answer.subQuestions) {
                            processQuestions(answer.subQuestions);
                        }
                    });
                } else if (question.type === "radio") {
                    const selected = question.answers
                        ? question.answers.find((answer) => answer.check === "Yes")
                        : null;
                    result[question.id] = selected ? selected.id : null;
                } else if (question.type === "text" || question.type === "textarea") {
                    result[question.id] = question.answerText || "";
                }
            });
        };

        if (data.questions) {
            processQuestions(submission.questions);
        }


        return result;
    };

    useEffect(() => {
        if (form) {
            const newSchema = buildSchema(form.questions);
            setSchema(newSchema);
        }
    }, [form]);

    const renderFields = (questions) => {
        return questions.map(question => {
            const fieldKey = question.id;

            if (!schema[fieldKey]) return null;

            const hasSubQuestions = question.answers?.some(answer => answer.subQuestions?.length > 0);

            const renderField = () => {
                switch (question.type) {
                    case 'text':
                        return <InputFormItem inputProps={{
                            readOnly: isDisabled,
                        }} key={fieldKey} name={fieldKey} schema={schema} />;
                    case 'radio':
                        return <RadioFormItem
                            errorMessage={t("This field is required")}

                            inputProps={{
                                disabled: isDisabled,
                            }} key={fieldKey} name={fieldKey} schema={schema} required={schema[fieldKey].required} />;
                    case 'checkbox':
                        return <CheckboxFormItem
                            errorMessage={t("This field is required")}
                            inputProps={{
                                disabled: isDisabled,
                            }} key={fieldKey} name={fieldKey} required={schema[fieldKey].required} schema={schema} currentSubQ={currentSubQ} updateValueSubQ={setCurrentSubQ} />;
                    case 'textarea':
                        return <TextAreaFormItem inputProps={{
                            readOnly: isDisabled,
                        }} key={fieldKey} name={fieldKey} schema={schema} />;
                    default:
                        return null;
                }
            };

            return (
                <div key={fieldKey}>
                    <Row>
                        <Col
                            style={{
                                borderRight: (hasSubQuestions && currentSubQ) ? '1px solid #c1ccd3' : 'none',
                            }}
                        >{renderField()}</Col>
                        {hasSubQuestions &&
                            <Col>
                                {question.answers.map(answer => {
                                    const answerKey = `${fieldKey}.${answer.id}`;
                                    const isChecked = formik.values[fieldKey] === answer.id || (Array.isArray(formik.values[fieldKey]) && formik.values[fieldKey].includes(answer.id)) && currentSubQ === answer.id;
                                    return (
                                        <div key={answerKey}>
                                            {isChecked && renderFields(answer.subQuestions)}
                                        </div>
                                    );
                                })}
                            </Col>}
                    </Row>
                </div>
            );
        });
    };

    return (
        <>{
            form &&
            (
                submission ?
                    <div>
                        {formLoading && <Loader />}
                        <h3>{form?.name}</h3>
                        <hr />
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                {form?.questions && renderFields(form.questions)}
                            </form>
                        </FormikProvider>
                        <hr />
                    </div>

                    :
                    <Widget>
                        {formLoading && <Loader />}
                        <h3>{form?.name}</h3>
                        <hr />
                        <FormikProvider value={formik}>
                            <form onSubmit={formik.handleSubmit}>
                                {form?.questions && renderFields(form.questions)}
                                <ButtonToolbar className="mt-3">
                                    <Button className="ms-0" disabled={submitting} color={"primary"} type="submit">{submitting && <i className='fa fa-spin fa-spinner me-2'></i>}{t("Save")}</Button>
                                    <Button type="button" onClick={formik.resetForm}>{t("Reset")}</Button>
                                </ButtonToolbar>
                            </form>
                        </FormikProvider>
                    </Widget>
            )
        }</>
    );
}));

export default FormSubmit;
