import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { dismissAlert } from '../../actions/alerts';
import s from './Sidebar.module.scss';
import LinksGroup from './LinksGroup/LinksGroup';
import { openSidebar, closeSidebar, changeActiveSidebarItem } from '../../actions/navigation';
import isScreen from '../../core/screenHelper';
import { logoutUser } from '../../actions/auth';
import User from '../../images/sidebar/basil/User';
import ShoppingCart from '../../images/sidebar/basil/ShoppingCart';
import Logo from '../../images/logo-sidebar.png';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    const sidebarStatic = useSelector((state) => state.navigation.sidebarStatic);
    const sidebarOpened = useSelector((state) => state.navigation.sidebarOpened);
    const activeItem = useSelector((state) => state.navigation.activeItem);

    const onMouseEnter = useCallback(() => {
        if (!sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
            const paths = location.pathname.split('/');
            paths.pop();
            dispatch(openSidebar());
            dispatch(changeActiveSidebarItem(paths.join('/')));
        }
    }, [dispatch, sidebarStatic, location.pathname]);

    const onMouseLeave = useCallback(() => {
        if (!sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
            dispatch(closeSidebar());
            dispatch(changeActiveSidebarItem(null));
        }
    }, [dispatch, sidebarStatic]);

    const dismissAlertById = useCallback(
        (id) => {
            dispatch(dismissAlert(id));
        },
        [dispatch]
    );

    const doLogout = useCallback(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    useEffect(() => {
        console.log(activeItem);
    }, [activeItem]);

    return (
        <div
            className={`${(!sidebarOpened && !sidebarStatic) ? s.sidebarClose : ''} ${s.sidebarWrapper}`}
        >
            <nav onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={s.root}>
                <header className={s.logo}>
                    <a href="">
                        <img src={Logo} alt="logo" />
                    </a>
                </header>
                <ul className={s.nav}>
                    <LinksGroup
                        onActiveSidebarItemChange={(activeItem) => dispatch(changeActiveSidebarItem(activeItem))}
                        activeItem={activeItem}
                        header={t("Companies")}
                        isHeader
                        labelColor="danger"
                        iconElement={<User />}
                        conName="flaticon-user"
                        link="/app/companies"
                        index="companies"
                        label=""
                        exact={false}
                        childrenLinks={[
                            {
                                header: t('All companies'), link: '/app/companies',
                            },

                            {
                                header: t("My company"),
                                link: '/app/companies/edit',
                            },

                        ]}
                    />
                    <LinksGroup
                        onActiveSidebarItemChange={(newActiveItem) =>
                            dispatch(changeActiveSidebarItem(newActiveItem))
                        }
                        activeItem={activeItem}
                        header={t("Quotes")}
                        isHeader
                        labelColor="danger"
                        iconElement={<ShoppingCart />}
                        conName="flaticon-user"
                        link="/app/quotes"
                        index="quotes"
                        label=""
                        exact={false}
                        childrenLinks={[
                            {
                                header: t('My quotes'),
                                link: '/app/quotes',
                            },
                            {
                                header: t('Create quote'),
                                link: '/app/quotes/create',
                            },
                        ]}
                    />



                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
