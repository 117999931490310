import React, { useState, useEffect, useCallback, memo } from 'react';
import axios from 'axios';
import { Button, Table, Input, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, ButtonToolbar } from 'reactstrap';
import Widget from 'components/Widget';
import { useTranslation } from "react-i18next";
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import s from '../Users.module.scss';
import GoBack from '../../../components/GoBack';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const UsersListTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const limit = 50;

  const fetchData = useCallback(async (keyword, offsetValue) => {
    setLoading(true);
    try {
      const response = await axios.get('/users', {
        params: {
          q: keyword,
          limit: limit + 1,
          offset: offsetValue,
        },
      });

      const data = response.data;

      if (data.length > limit) {
        setHasMore(true);

      } else {
        setHasMore(false);

      }
      setRows(data.slice(0, limit));
    } catch (error) {
      console.error('Failed to fetch users:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const debouncedFetch = useCallback(
    debounce((keyword) => {
      setOffset(0);
      fetchData(keyword, 0);
    }, 500),
    [fetchData]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchKeyword(value);
    debouncedFetch(value);
  };

  useEffect(() => {
    fetchData('', 0);
  }, [fetchData]);

  const handleNext = () => {
    if (!hasMore || loading) return;
    const newOffset = offset + limit;
    setOffset(newOffset);
    fetchData(searchKeyword, newOffset);
  };

  const handlePrevious = () => {
    if (offset === 0 || loading) return;
    const newOffset = Math.max(offset - limit, 0);
    setOffset(newOffset);
    fetchData(searchKeyword, newOffset);
  };

  const handleDelete = async () => {
    if (!userIdToDelete) return;

    try {
      setLoading(true);
      await axios.delete(`/users/${userIdToDelete}`);
      setRows((prev) => prev.filter((row) => row.id !== userIdToDelete));
      setDeleteModalOpen(false);
    } catch (error) {
      console.error('Failed to delete user:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <GoBack />
      <Row>
        <Col md={3}>
          <div className="page-top-line">
            <h2 className="page-title">{t("Users")}</h2>
          </div>
        </Col>
        <Col md={3}>
        <Input
            type="text"
            placeholder={t("Search")}
            className="bg-white"
            value={searchKeyword}
            onChange={handleSearchChange}
          />
        </Col>
        <Col md={6}>
          <div className="d-flex justify-content-end">
            <Button color="primary" onClick={() => dispatch(push("/admin/users/new"))}>{t("Add user")}</Button>
          </div>
        </Col>
      </Row>
      <Widget>
        <div>
        

          <Table responsive className={`${s.usersListTableMobile}`}>
            <thead>
              <tr>
                <th>{t("First name")}</th>
                <th>{t("Last name")}</th>
                <th>{t("Phone")}</th>
                <th>{t("Email")}</th>
                <th>{t("Role")}</th>
                <th className="text-end" style={{
                  width: 250
                }}>{t("Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <Spinner color="primary" />
                  </td>
                </tr>

              ) :
                rows.length > 0 ? (
                  rows.map((row) => (
                    <tr key={row.id}>
                      <td>{row.firstname}</td>
                      <td>{row.lastname}</td>
                      <td>{row.phone}</td>
                      <td>{row.email}</td>
                      <td>{row.role}</td>
                      <td>
                        <ButtonToolbar className={`d-flex justify-content-end`}>

                          <Button
                            color="success"
                            size="xs"
                            onClick={() => dispatch(push(`/admin/users/${row.id}/edit`))}
                          >
                            {t("Edit")}
                          </Button>
                          {!row?.verify && <ApproveUserButton userId={row.id} />}
                          <Button
                            color="danger"
                            size="xs"
                            onClick={() => {
                              setUserIdToDelete(row.id);
                              setDeleteModalOpen(true);
                            }}
                          >
                            {t("Delete")}
                          </Button>
                        </ButtonToolbar>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      {t("No results found.")}
                    </td>
                  </tr>
                )}
            </tbody>
          </Table>


          <Row className="mt-md">
            <Col md={6} xs={12}>
              <Button disabled={offset === 0 || loading} onClick={handlePrevious}>
                {t("Previous")}
              </Button>
            </Col>
            <Col md={6} xs={12} className="d-flex justify-content-end">
              <Button disabled={!hasMore || loading} onClick={handleNext}>
                {t("Next")}
              </Button>
            </Col>
          </Row>
        </div>
      </Widget>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={deleteModalOpen} toggle={() => setDeleteModalOpen(false)}>
        <ModalHeader toggle={() => setDeleteModalOpen(false)}>Confirm Delete</ModalHeader>
        <ModalBody className="bg-white">
          {t("Are you sure you want to delete this user?")}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setDeleteModalOpen(false)}>
            {t("Cancel")}
          </Button>
          <Button color="danger" onClick={handleDelete}>
            {t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UsersListTable;


export const ApproveUserButton = memo(({ userId }) => {
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const { t } = useTranslation();

  const handleApprove = async () => {
    if (loading || approved) return;

    setLoading(true);
    try {
      await axios.post(`/users/${userId}/approve`);
      setApproved(true);
    } catch (error) {
      console.error("Approval failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button type='button' size="xs" color="info" onClick={handleApprove} disabled={loading}>
      {loading ? <i className='fa fa-spin fa-spinner' /> : (approved ?
        (<><i className="fa fa-check"></i> {t("Approved")}</>)
        : t("Approve"))}
    </Button>
  );
});

