import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';

const TextAreaFormItem = ({
  name,
  schema,
  hint,
  size,
  type = 'text',
  placeholder,
  autoFocus,
  autoComplete,
  inputProps,
  errorMessage,
  required = false,
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(name);

  const { label } = schema[name];

  const sizeLabelClassName = {
    small: 'col-form-label-sm',
    large: 'col-form-label-lg',
  }[size] || '';

  const sizeInputClassName = {
    small: 'form-control-sm',
    large: 'form-control-lg',
  }[size] || '';

  const showError = meta.touched && meta.error;

  return (
    <div className="form-group">
      {!!label && (
        <label
          className={`col-form-label ${required ? 'required' : ''} ${sizeLabelClassName}`}
          htmlFor={name}
        >
          {t(label)}
        </label>
      )}
      <textarea
        id={name}
        {...field}
        type={type}
        placeholder={placeholder || undefined}
        autoFocus={autoFocus || undefined}
        autoComplete={autoComplete || undefined}
        className={`form-control ${sizeInputClassName} ${showError ? 'is-invalid' : ''}`}
        {...inputProps}
      />
      {showError && (
        <div className="invalid-feedback">
          {t(meta.error || errorMessage)}
        </div>
      )}
      {!!hint && <small className="form-text text-muted">{t(hint)}</small>}
    </div>
  );
};

export default TextAreaFormItem;
