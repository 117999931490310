import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Row, Col, Input, FormGroup, Label } from 'reactstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import useFetch from '../../../components/hooks/use-fetch';

const EsignForm = memo(forwardRef(({ setLoading, quoteId, buyerCompanyId, sellerCompanyId, esignStartDate }, ref) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      buyerSignatureName: '',
      buyerSignatureEmail: '',
      sellerSignatureName: '',
      sellerSignatureEmail: '',
    },
    validationSchema: Yup.object({
      buyerSignatureName: Yup.string().required(t('Buyer signature name is required')),
      buyerSignatureEmail: Yup.string()
        .email(t('Invalid email'))
        .required(t('Buyer signature email is required')),
      sellerSignatureName: Yup.string().required(t('Seller signature name is required')),
      sellerSignatureEmail: Yup.string()
        .email(t('Invalid email'))
        .required(t('Seller signature email is required')),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await axios.post(`/quotes/${quoteId}/esign-start`, values);
        toast.success(t('Agreement created successfully!'));
      } catch (err) {
        toast.error(err.response?.data?.message || t('Something went wrong!'));
      } finally {
        setLoading(false);
      }
    },
  });

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
  }));

  const {data: companyBuyer} = useFetch(`/companies/${buyerCompanyId}`);
  const {data: companySeller} = useFetch(`/companies/${sellerCompanyId}`);

  useEffect(() => {
    if (companyBuyer) {
      formik.setFieldValue('buyerSignatureName', companyBuyer.signatureName);
      formik.setFieldValue('buyerSignatureEmail', companyBuyer.signatureEmail);
    }

    if (companySeller) {
      formik.setFieldValue('sellerSignatureName', companySeller.signatureName);
      formik.setFieldValue('sellerSignatureEmail', companySeller.signatureEmail);
    }
  }
  , [companyBuyer, companySeller]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="mt-4">
        <h3>{t('Avtalspart')}</h3>
        <Row>
          <Col md={6}>
            <FormGroup className="mt-3">
              <Label for="buyerSignatureName">{t('Buyer signature name')}</Label>
              <Input
                type="text"
                name="buyerSignatureName"
                id="buyerSignatureName"
                value={formik.values.buyerSignatureName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.buyerSignatureName && !!formik.errors.buyerSignatureName}
              />
              {formik.touched.buyerSignatureName && formik.errors.buyerSignatureName && (
                <div className="text-danger mt-1">{formik.errors.buyerSignatureName}</div>
              )}
            </FormGroup>

            <FormGroup className="mt-3">
              <Label for="buyerSignatureEmail">{t('Buyer signature email')}</Label>
              <Input
                type="email"
                name="buyerSignatureEmail"
                id="buyerSignatureEmail"
                value={formik.values.buyerSignatureEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.buyerSignatureEmail && !!formik.errors.buyerSignatureEmail}
              />
              {formik.touched.buyerSignatureEmail && formik.errors.buyerSignatureEmail && (
                <div className="text-danger mt-1">{formik.errors.buyerSignatureEmail}</div>
              )}
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup className="mt-3">
              <Label for="sellerSignatureName">{t('Seller signature name')}</Label>
              <Input
                type="text"
                name="sellerSignatureName"
                id="sellerSignatureName"
                value={formik.values.sellerSignatureName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.sellerSignatureName && !!formik.errors.sellerSignatureName}
              />
              {formik.touched.sellerSignatureName && formik.errors.sellerSignatureName && (
                <div className="text-danger mt-1">{formik.errors.sellerSignatureName}</div>
              )}
            </FormGroup>

            <FormGroup className="mt-3">
              <Label for="sellerSignatureEmail">{t('Seller signature email')}</Label>
              <Input
                type="email"
                name="sellerSignatureEmail"
                id="sellerSignatureEmail"
                value={formik.values.sellerSignatureEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.sellerSignatureEmail && !!formik.errors.sellerSignatureEmail}
              />
              {formik.touched.sellerSignatureEmail && formik.errors.sellerSignatureEmail && (
                <div className="text-danger mt-1">{formik.errors.sellerSignatureEmail}</div>
              )}
            </FormGroup>
           
            
          </Col>
        </Row>
        {esignStartDate && (<FormGroup className="mt-3">
              <Label className='font-italic' for="sellerSignatureEmail">{t('Signature started')} {moment(esignStartDate).format("YYYY MM DD")}</Label>
            </FormGroup>)}

      </form>
    </div>
  );
}));

export default EsignForm;
